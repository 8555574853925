<template>
  <div id="app" class="leader">
    <Nav />
    <div class="mt50">
      <img :src="require(`@/assets/banner/banner-07${$i18n.locale == 'zh'?'z':'e'}.png`)" />
      <div class="p20" v-for="(item,key) in 6" :key="key">
            <img v-lazy="require(`@/assets/lead/lead-${key+8}${$i18n.locale == 'zh'?'z':'e'}.png`)" alt="">
          </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import Nav from "@/components/nav.vue";
import Footer from "@/components/footer.vue";
export default {
  name: "App",
  components: {
    Nav,
    Footer,
  },
  data() {
    return {};
  },
  computed: {},
  mounted() {},
  methods: {},
};
</script>

<style lang="scss">
.leader {
  background: #f2f6f7;
  img {
    max-width: 100%;
  }
}
</style>

